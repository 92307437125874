import * as React from "react";
import NavBar from "./navbar";
import "./assets/styles.css";
import democontent from "./assets/CogniTraxHowItWorksCokeBottleDemo.gif";

const footerStyle = {
  textAlign: "center",
  color: "#716969",
  fontSize: 10,
};
const imgStyle = {
  color: "#232129",
  padding: 10,
  width: "100%",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};
// const textStyle = {
//   textAlign : "center",
//   color: "#f0f0f0",
//   fontSize: 24
// }

const pageStyles = {
  color: "#232129",
  backgroundColor: "#ffffff",
  padding: 0,
  height: "100%",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  texColor: "#ffffff",
};

// center video in page
// const vidStyle = {
//     left: "50%",
//     position: "absolute",
//     top: "50%",
//     transform: "translate(-50%, -50%)",
//     maxWidth : "20%",
//     height : "auto"
// }

const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <title>Cogni-Trax</title>
      <NavBar />

      <div className="content">
        <img src={democontent} style={imgStyle} alt="cogni-trax technology" />
      </div>

      <div id="footer" style={footerStyle}>
        <p className="copyright">&copy; Cogni-Trax </p>
      </div>
    </main>
  );
};

export default IndexPage;
